var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.cnp)?_c('b-card',{staticClass:"a-card-menu"},[_c('h3',{staticClass:"w-color-black fw-bold mb-4"},[_vm._v(_vm._s(_vm.title))]),_c('b-list-group',{attrs:{"flush":""}},_vm._l((_vm.cnp),function(cnpItem,i){return _c('b-list-group-item',{key:i,class:{ completed: _vm.cnpCompleted(cnpItem) },on:{"click":function($event){$event.preventDefault();return _vm.onClick(cnpItem)}}},[_c('div',{staticClass:"d-flex align-items-center pl-2"},[_c('div',{staticClass:"image",class:{ completed: _vm.showCompleted && _vm.cnpCompleted(cnpItem) }},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.cnpThumbnail(cnpItem) ||
              require('/public/coursePlaceholder.png')}}),(_vm.showCompleted && _vm.cnpCompleted(cnpItem))?_c('svg-checked-circle-icon',{staticClass:"image__completed-icon"}):_vm._e()],1),_c('span',[(cnpItem._model === 'path')?_c('svg-series-icon',{staticClass:"icon mr-1"}):_vm._e(),(
              !_vm.isLoggedIn &&
              cnpItem.attributes.public &&
              _vm.coursePlayerMode === 'path'
            )?_c('svg-open-lock-icon',{staticClass:"is-public-icon w-color-dark-green"}):_vm._e(),(
              !_vm.isLoggedIn &&
              _vm.coursePlayerMode === 'path' &&
              !cnpItem.attributes.public
            )?_c('svg-closed-lock-icon',{staticClass:"is-public-icon w-color-dark-red"}):_vm._e(),_vm._v(" "+_vm._s(cnpItem.attributes.name)+" ")],1)])])}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }