<template>
  <b-card class="a-card-menu" v-if="cnp">
    <h3 class="w-color-black fw-bold mb-4">{{ title }}</h3>
    <b-list-group flush>
      <b-list-group-item
        v-for="(cnpItem, i) of cnp"
        :key="i"
        @click.prevent="onClick(cnpItem)"
        :class="{ completed: cnpCompleted(cnpItem) }"
      >
        <div class="d-flex align-items-center pl-2">
          <div
            class="image"
            :class="{ completed: showCompleted && cnpCompleted(cnpItem) }"
          >
            <img
              :src="
                cnpThumbnail(cnpItem) ||
                require('/public/coursePlaceholder.png')
              "
              class="img-fluid"
            />
            <svg-checked-circle-icon
              v-if="showCompleted && cnpCompleted(cnpItem)"
              class="image__completed-icon"
            />
          </div>
          <span>
            <svg-series-icon
              v-if="cnpItem._model === 'path'"
              class="icon mr-1"
            />
            <svg-open-lock-icon
              v-if="
                !isLoggedIn &&
                cnpItem.attributes.public &&
                coursePlayerMode === 'path'
              "
              class="is-public-icon w-color-dark-green"
            />
            <svg-closed-lock-icon
              v-if="
                !isLoggedIn &&
                coursePlayerMode === 'path' &&
                !cnpItem.attributes.public
              "
              class="is-public-icon w-color-dark-red"
            />
            {{ cnpItem.attributes.name }}
          </span>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import { get } from "lodash";
import { resolveCmsMediaUrl } from "/utils/helpers";

import * as Sentry from "@sentry/vue";

export default {
  components: {
    "svg-series-icon": () => import("/assets/icons/iconSeries.svg?inline"),
    "svg-checked-circle-icon": () =>
      import("/assets/icons/checkedCircle.svg?inline"),
    "svg-open-lock-icon": () => import("/assets/icons/openLock.svg?inline"),
    "svg-closed-lock-icon": () => import("/assets/icons/closedLock.svg?inline"),
  },
  props: {
    title: { required: true, type: String },
    showCompleted: { type: Boolean, default: false },
    /** Combination of Courses and Paths */
    cnp: { required: true, type: Array },
    coursePlayerMode: { default: "course", type: String },
    pathSlug: { required: false, type: String },
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "user"]),
  },
  data() {
    return {
      isInPath: this.coursePlayerMode === "path",
    };
  },
  mounted() {
    this._loadUser();
  },
  methods: {
    async _loadUser() {
      try {
        await this.$store.dispatch("auth/user");
      } catch (error) {
        Sentry.captureException(error);
        console.warn(error);
      }
    },

    onClick(cnpItem) {
      this._loadUser();
      const cnpUrl = this.cnpLink(cnpItem);

      if (
        !this.isLoggedIn &&
        !get(cnpItem, "attributes.public") &&
        cnpItem._model === "course"
      ) {
        localStorage.setItem("LAST_FROM_ROUTE", cnpUrl);
        this.EventBus.$emit("locked-content-accessed");
        return;
      }

      this.$router.push(cnpUrl);
    },

    /** Generate a link based on an underlying model: Course or Path */
    cnpLink(cnpItem) {
      const cnpSlug = cnpItem.attributes.slug || cnpItem.id;

      if (cnpItem._model === "path") {
        return `/seria/${cnpSlug}`;
      } else {
        return this.isInPath
          ? `/kurz/${cnpSlug}?seria=${this.pathSlug}`
          : `/kurz/${cnpSlug}`;
      }
    },
    cnpThumbnail(cnpItem) {
      return resolveCmsMediaUrl(
        get(
          cnpItem,
          "attributes.thumbnail.data.attributes.formats.small.url"
        ) || get(cnpItem, "attributes.thumbnail.data.attributes.url")
      );
    },
    cnpCompleted(cnpItem) {
      if (!this.isLoggedIn) {
        return false;
      }

      if (cnpItem._model === "path") {
        return this.user.pathCertificates.some(
          (certificate) => certificate.path.id === cnpItem.id
        );
      }

      return this.user.courseCertificates.some(
        (certificate) => certificate.course.id === cnpItem.id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  border-bottom: 0;
  padding-bottom: 0;
}

.icon::v-deep path {
  fill: #eb1b69;
}

.list-group-item {
  font-weight: bold;

  &.completed {
    background-color: rgba(144, 238, 144, 0.2);
  }

  &.router-link-exact-active {
    background-color: var(--a-color-primary);
    color: var(--a-color-white);
  }

  &:hover {
    text-decoration: none;
    background-color: var(--a-color-blue-light);
    cursor: pointer;
  }

  .image {
    flex: 0 0 4.5rem;
    margin-right: 1.5rem;
    position: relative;

    &.completed {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: lightgreen;
        opacity: 0.9;
        z-index: 1;
      }
    }

    .image__completed-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 25px;
      color: var(--a-color-black);
      z-index: 2;
    }
  }
}

.is-public-icon {
  width: 1.5rem;
  margin-right: 0.25rem;
}
</style>
